import angular from '../assets/icons/angular.svg';
import contentful from '../assets/icons/contentful.svg';
import docker from '../assets/icons/docker.svg';
import firebase from '../assets/icons/firebase.svg';
import gatsby from '../assets/icons/gatsby.svg';
import gsap from '../assets/icons/gsap.svg';
import jasmine from '../assets/icons/jasmine.svg';
import jest from '../assets/icons/jest.svg';
import nest from '../assets/icons/nest.svg';
import next from '../assets/icons/next.svg';
import ngrx from '../assets/icons/ngrx.svg';
import nuxt from '../assets/icons/nuxt.svg';
import prisma from '../assets/icons/prisma.svg';
import react from '../assets/icons/react.svg';
import redux from '../assets/icons/redux.svg';
import ts from '../assets/icons/ts.svg';
import vue from '../assets/icons/vue.svg';

const icons = {
	angular,
	contentful,
	firebase,
	gatsby,
	gsap,
	jest,
	next,
	nuxt,
	prisma,
	react,
	redux,
	vue,
	ts,
	docker,
	jasmine,
	ngrx,
	nest,
};

export default icons;
